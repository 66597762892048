import exp from "constants";
import React from "react";
import { Route, Link, Routes, BrowserRouter } from 'react-router-dom';
import AboutUs from "./AboutUs";
import Slideshow from "./slideshow";
import App from "./App";
import Service from "./Service";
import Contact from "./Contact";

export interface IApplicationProps { }
//function App() {
const Application: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/services" element={<Service />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Application;