import React, { useEffect, useState } from 'react';
import logo_trans from './images/techturn_logo_trans.png';

const Footer = () => {
    const [scrolled, setScrolled] = useState("nav-fixed");
    const topFunction = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <div>
            <footer className="footer-17">
                <div className="footer17_sur py-5">
                    <div className="container py-lg-5 py-md-4">
                        <div className="row footer17-top">
                            <div className="col-lg-4 footer17-top-left2_sur pr-lg-5">
                                <img width={'100px'} height={'100px'} src={logo_trans} alt="" className="img-fluid news-image" />
                                <br />
                                <a href="#get" className="btn btn-style btn-primary mt-lg-5 mt-4">Contact Us</a>
                            </div>
                            <div className="col-lg-4 footer17-top-left1_sur mt-lg-0 my-5">
                                <h6>Quick Links</h6>
                                <div className="footer-listw3-grids">
                                    <ul className="footer-listw3">
                                        <li><a href="index.html">Home</a></li>
                                        <li><a href="about.html">About</a></li>
                                    </ul>
                                    <ul className="footer-listw3">
                                        <li><a href="contact.html">Contact</a></li>
                                        <li><a href="#faq">FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 footer17-top-left4_sur footerw3-instagram pl-lg-5 ">
                                <h6>Social Media</h6>
                                <div className="row">
                                    <div className="col-4 img-gd-f">
                                        <a href="#"> <img src="assets/images/1.jpg" alt="" className="radius-image img-fluid" /></a>
                                    </div>
                                    <div className="col-4 img-gd-f">
                                        <a href="#"> <img src="assets/images/2.jpg" alt="" className="radius-image img-fluid" /></a>
                                    </div>
                                    <div className="col-4 img-gd-f">
                                        <a href="#"> <img src="assets/images/3.jpg" alt="" className="radius-image img-fluid" /></a>
                                    </div>
                                    <div className="col-4 img-gd-f mt-4">
                                        <a href="#"> <img src="assets/images/4.jpg" alt="" className="radius-image img-fluid" /></a>
                                    </div>
                                    <div className="col-4 img-gd-f mt-4">
                                        <a href="#"> <img src="assets/images/5.jpg" alt="" className="radius-image img-fluid" /></a>
                                    </div>
                                    <div className="col-4 img-gd-f mt-4">
                                        <a href="#"> <img src="assets/images/1.jpg" alt="" className="radius-image img-fluid" /></a>
                                    </div>
                                </div>
                                <ul className="footers-17_social mt-lg-5 mt-4">
                                    <li><a href="#url" className="twitter"><span className="fab fa-twitter"></span></a></li>
                                    <li><a href="#url" className="facebook"><span className="fab fa-facebook-f"></span></a></li>
                                    <li><a href="#url" className="linkedin"><span className="fab fa-linkedin-in"></span></a></li>
                                    <li><a href="#url" className="instagram"><span className="fab fa-instagram"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="copyright text-center">
                        <div className="container">
                            <div className="container">
                                <p className="copy-footer-29">© 2021 Secttore. All rights reserved | Designed by <a href="https://w3layouts.com"
                                    target="_blank">W3layouts</a></p>
                            </div>
                        </div>
                    </div>
                    <button onClick={topFunction} id="movetop" title="Go to top">
                        <i className="fas fa-long-arrow-alt-up"></i>
                    </button>
                </div>
            </footer>
        </div>
    );
}

export default Footer;