import React from 'react';
import { Fade, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import banner1 from './images/banner1.jpg';
import banner2 from './images/banner2.jpg';
import banner3 from './images/banner3.jpg';
import banner4 from './images/banner4.jpg';
import banner5 from './images/banner5.jpg';
import fitting from './images/1.jpg';
import factory from './images/2.jpg';
import quality from './images/3.jpg';
import evapouration_unit from './images/evapouration_unit.png';
import process_plant from './images/process_plant.png';
import software from './images/software_light.jpg';
import software_training from './images/softaware_training.jpg';
import manpower from './images/manpower.jpg';
import detailed_engineering_services from './images/detailed-engineering-services.jpg';
import first from './images/first.jpg';

import maire_tecnimont from './images/maire_tecnimont.png';
import aker from './images/aker.png';
import petrofac from './images/petrofac.png';
import toyo from './images/toyo.jpg';
import gea from './images/gea.png';
import burckhardt from './images/burckhardt.png';
import voltas from './images/voltas.png';
import spectrum from './images/spectrum.png';
import lambros from './images/lambros.png';
import dcwhite from './images/dcwhite.png';
import ips from './images/ips.png';
import technoquest from './images/technoquest.jpg';
import sulzer from './images/suzler.png';

const fadeImages = [
  {
    // url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    url: banner1,
    caption: 'First Slide'
  },
  {
    // url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
    url: banner2,
    caption: 'Second Slide'
  },
  {
    // url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    url: banner3,
    caption: 'Third Slide'
  },
  {
    // url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    url: banner4,
    caption: 'Fourth Slide'
  },
  {
    // url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    url: banner5,
    caption: 'Fifth Slide'
  },
];

const fadeClients = [
  {
    url: maire_tecnimont,
    caption: 'Maire Tecnimont'
  },
  {
    url: aker,
    caption: 'Aker Solutions'
  },
  {
    url: petrofac,
    caption: 'Petrofac'
  },
  {
    url: toyo,
    caption: 'Toyo Engineering'
  },
  {
    url: gea,
    caption: 'GEA'
  },
  {
    url: burckhardt,
    caption: 'Burckhardt Compression'
  },
  {
    url: voltas,
    caption: 'Voltas'
  },
  {
    url: spectrum,
    caption: 'Spectrum Pharmatech Consultant Pvt. Ltd.'
  },
  {
    url: lambros,
    caption: 'Lambros Global'
  },
  {
    url: dcwhite,
    caption: 'DC White Engineering Consultant'
  },
  {
    url: ips,
    caption: 'IPS Mehtalia'
  },
  {
    url: technoquest,
    caption: 'Technoquest'
  },
  {
    url: sulzer,
    caption: 'Sulzer Chemtech Pvt. Ltd.'
  },
];
const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '800px'
}

const clientStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '120px', width: '350px'
}

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Fade arrows={false}>
        {fadeImages.map((fadeImage, index) => (
          <div key={index}>
            <div style={{ ...divStyle, 'backgroundImage': `url(${fadeImage.url})` }}>
            </div>
          </div>))}
      </Fade>

      <div className="w3l-3-grids py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 pr-lg-3">
              <div className="title-content text-left">
                <h6 className="title-subhny mb-2">About Us</h6>
                <h3 className="title-w3l"> The clever industrial design services for Off-shore & On-Shore business.</h3>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-md-5 mt-4 pl-lg-5">
              <p className="pr-lg-5">&emsp;&emsp;TechTurn Engineering Services LLP are a service  provider to all Engineering Design Companies for
                all kind of Projects.</p>
              <p className="pr-lg-5">&emsp;&emsp;Our Team of highly skilled professionals,  engineers, and designers take pride in consistently  providing our customer with industry leading  expertise in their respective disciplines.</p>
              <p className="pr-lg-5">&emsp;&emsp;We believe in customer centric organisation  and therefore we always put our efforts towards  complete custormer satisfaction.</p>

            </div>
          </div>
          <div className="row pt-md-0 pt-5">
            <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
              <div className="grids3-info">
                <a href="#" className="d-block zoom"><img src={process_plant} alt="" className="img-fluid news-image" /></a>
                <div className="w3-grids3-info">
                  <h6><a href="#category" className="category d-block">3D Modeling</a></h6>
                  <h5 style={{ color: 'white' }}>Our Ingenious Approach to Engineering Design</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-md-0 mt-4 px-lg-0 grids3-info2">
              <div className="grids3-info second">
                <a href="blog-single.html" className="d-block zoom"><img src={evapouration_unit} alt=""
                  className="img-fluid news-image" /></a>
                <div className="w3-grids3-info second">
                  <h6><a href="#category" className="category d-block">Piping Stress Analysis</a></h6>
                  <h5 style={{ color: 'white' }}>Compliance & Fitness Assessments With Our Expert</h5>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-4 px-lg-0">
              <div className="grids3-info">
                <a href="blog-single.html" className="d-block zoom"><img src={software} alt=""
                  className="img-fluid news-image" /></a>
                <div className="w3-grids3-info">
                  <h6><a href="#category" className="category d-block">Software Customization</a></h6>
                  <h5 style={{ color: 'white' }}>Industry best Software Customization to ease your work</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-4 px-lg-0">
              <div className="grids3-info">
                <a href="blog-single.html" className="d-block zoom"><img src={software_training} alt=""
                  className="img-fluid news-image" /></a>
                <div className="w3-grids3-info">
                  <h6><a href="#category" className="category d-block">Software Training</a></h6>
                  <h5 style={{ color: 'white' }}>Get training from expert to improve the staff skill</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-4 px-lg-0">
              <div className="grids3-info">
                <a href="blog-single.html" className="d-block zoom"><img src={manpower} alt=""
                  className="img-fluid news-image" /></a>
                <div className="w3-grids3-info">
                  <h6><a href="#category" className="category d-block">Man power</a></h6>
                  <h5 style={{ color: 'white' }}>Industry Expert manpower to excel your business</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-4 px-lg-0">
              <div className="grids3-info">
                <a href="blog-single.html" className="d-block zoom"><img src={detailed_engineering_services} alt=""
                  className="img-fluid news-image" /></a>
                <div className="w3-grids3-info">
                  <h6><a href="#category" className="category d-block">Detail Engineering</a></h6>
                  <h5 style={{ color: 'white' }}>One-Stop Destination For Focused Engineering Solutions</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="w3l-about-ab w3l-content-8" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row">
              <div className="col-lg-6 left-wthree-img pr-lg-5">
                <div className="imgw3l-ab w3l-news">
                  <div className="grids5-info">
                    <a href="#" className="d-block zoom"><img src={first} alt="" className="img-fluid news-image" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-lg-5 mt-lg-0 mt-5">
                <div className="title-content text-left">
                  <h6 className="title-subhny mb-2">Why Choose Us</h6>
                  <h3 className="title-w3l mb-4"><i className="fas fa-quote-left"></i><i> Turning Ideas into
                    <br /> &emsp;&emsp;Technical Triumphs</i></h3>
                </div>
                <p className="pr-lg-5">We serves to following industries,
                </p>
                <ul className="icon-list-items mt-5">
                  <li className="icon-list-item">
                    <i aria-hidden="true" className="fas fa-check"></i>
                    Power & Energy
                  </li>
                  <li className="icon-list-item ml-lg-4">
                    <i aria-hidden="true" className="fas fa-check"></i>
                    Mechanical Works
                  </li>
                  <li className="icon-list-item">
                    <i aria-hidden="true" className="fas fa-check"></i>
                    Chemical
                  </li>
                  <li className="icon-list-item ml-lg-4">
                    <i aria-hidden="true" className="fas fa-check"></i>
                    Oil and Gas
                  </li>
                </ul>
                <a href="services.html" className="btn btn-style btn-primary mt-lg-5 mt-4">More Services

                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-featureshny py-5" id="features">
        <div className="container py-md-5">
          <div className="row">
            <div className="col-lg-5 pr-lg-5 mb-lg-0 mb-5">
              <div className="w3l-header-sec text-left mb-md-5 mb-4">
                <h6 className="title-subhny mb-2">Our Stats</h6>
                <h5 className="">Most Modern & Powerful Solutions
                  to Industry in the world</h5>
                <a href="about.html" className="btn btn-primary btn-white btn-style mt-lg-5 mt-4"> Read More </a>
              </div>
            </div>
            <div className="col-lg-7 statsw3-left pl-lg-5">
              <div className="row w3l-stats-section stats-con">
                <div className="col-lg-4 col-6 stats_info counter_grid">
                  <i className="far fa-smile"></i>
                  <p className="counter">1200 </p>
                  <h3>Happy clients</h3>
                </div>
                <div className="col-lg-4 col-6 stats_info counter_grid mt-md-0 mt-0">
                  <i className="fas fa-trophy"></i>
                  <p className="counter">2500</p>
                  <h3>Projects done</h3>
                </div>
                <div className="col-lg-4 col-6 stats_info counter_grid2 mt-md-0 mt-4">
                  <i className="fas fa-project-diagram"></i>
                  <p className="counter">1545 </p>
                  <h3>Awards won</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-products w3l-faq-block py-5" id="projects">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row ">
            <div className="col-lg-6 mx-auto pr-lg-5 w3l-news">
              <div className="grids5-info">
                <a href="#" className="d-block zoom"><img src="assets/images/ab1.jpg" alt="" className="img-fluid news-image" /></a>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-sm-5 mt-5">
              <h6 className="title-subhny mb-2"><span>Ask by Client </span></h6>
              <h3 className="title-w3l mb-lg-5 mb-4">Frequently Asked Questions</h3>
              <div className="accordion">
                <div className="accordion-item">
                  <button id="accordion-button-1" aria-expanded="true"><span className="accordion-title">Question Number
                    one?</span><span className="icon" aria-hidden="true"></span></button>
                  <div className="accordion-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                      ut. Ut tortor.</p>
                  </div>
                </div>
                <div className="accordion-item">
                  <button id="accordion-button-2" aria-expanded="false"><span className="accordion-title">Question Number
                    Two?</span><span className="icon" aria-hidden="true"></span></button>
                  <div className="accordion-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                      ut. Ut pretium.</p>
                  </div>
                </div>
                <div className="accordion-item">
                  <button id="accordion-button-3" aria-expanded="false"><span className="accordion-title">Question Number
                    Three?</span><span className="icon" aria-hidden="true"></span></button>
                  <div className="accordion-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                      ut. Ut tortor.</p>
                  </div>
                </div>
                <div className="accordion-item">
                  <button id="accordion-button-4" aria-expanded="false"><span className="accordion-title">Question Number
                    Four?</span><span className="icon" aria-hidden="true"></span></button>
                  <div className="accordion-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis
                      ut. Ut potenti.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-clients py-5" id="clients">
        <div className="cusrtomer-layout py-5">
          <div className="container">
            <div className="heading text-center mx-auto">
              <h6 className="title-subhny mb-2">Testimonials</h6>
              <h3 className="title-w3l mb-5">Client’s Testimonials

              </h3>
            </div>
            <div className="testimonial-width pb-5">
              <div className="testimonial-content">
                <div className="testimonial">
                  <blockquote>
                    <i className="fas fa-quote-left"></i>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit beatae
                      laudantium
                      voluptate rem ullam dolore nisi voluptatibus esse quasi. Lorem ipsum dolor sit amet elit. Non
                      quae, fugiat nihil ad. Lorem ipsum dolor sit amet.</p>
                  </blockquote>
                  <div className="testi-des">
                    <div className="test-img"><img src="assets/images/team1.jpg" className="img-fluid" alt="client-img" />
                    </div>
                    <div className="peopl align-self">
                      <h3>John wilson</h3>
                      <p className="indentity">Example City</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-subscribe-content py-5">
        <div className="container py-md-4 py-3 text-center">
          <div className="row">
            <div className="col-lg-4">
              <div className="header-section text-left">
                <h6 className="title-subhny mb-2"><span>Join Us</span></h6>
                <h3 className="title-w3l">Stay
                  Updated!</h3>
                <p className="mt-2">Get Updates By Subscribe Our Weekly Newsletter</p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="subscribe">
                <form action="#" method="post" className="subscribe-wthree pt-2 mt-5">
                  <div className="d-sm-flex flex-wrap subscribe-wthree-field">
                    <input className="form-control" type="email" placeholder="Enter your email..." name="email" required />
                    <button className="btn btn-style btn-primary" type="submit">Subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Slide slidesToScroll={2} slidesToShow={3} indicators={true}>
        {fadeClients.map((fade, index) => (
          <div key={index}>
            <div style={{ ...clientStyle, 'backgroundImage': `url(${fade.url})` }}>
            </div>
          </div>))}
      </Slide>
    </div>

  )
}
export default Slideshow;