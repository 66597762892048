import React, { ReactNode, useEffect, useState } from 'react';
import Carousel, { CarouselItem } from "./Carousel";
import { Fade, Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import banner1 from './images/banner1.jpg';
import banner2 from './images/banner2.jpg';
import banner3 from './images/banner3.jpg';
import banner4 from './images/banner4.jpg';
import banner5 from './images/banner5.jpg';

import team1 from './images/team1.jpg';
import team2 from './images/team2.jpg';
import team3 from './images/team3.jpg';
import team4 from './images/team4.jpg';

import about1 from './images/about/DSC_7482.jpg';
import about2 from './images/about/DSC_7484.jpg';
import about3 from './images/about/DSC_7485.jpg';
import about4 from './images/about/DSC_7530.jpg';
import about5 from './images/about/DSC_7567.jpg';
import about6 from './images/about/Photo-1.jpg';
import about7 from './images/about/Photo-2.jpg';
import about8 from './images/about/Sports_1.jpg';
import about9 from './images/about/TT_Banner.jpg';
import about10 from './images/about/TT_Director_1.jpg';
import about11 from './images/about/TT_Director_2.jpg';
import about12 from './images/about/TT_Staff.jpg';
import about13 from './images/about/TT_Training_1.jpg';
import about14 from './images/about/TT_Training_2.jpg';
import about15 from './images/about/TT_full_Staff.jpg';
import about16 from './images/about/Working_Staff_1.jpg';
import about17 from './images/about/Working_Staff_2.jpg';
import about18 from './images/about/Working_Staff_3.jpg';
import about19 from './images/about/about_1.jpg';
import about20 from './images/about/about_2.jpg';

import { isWhiteSpaceSingleLine } from 'typescript';
import Header from './Header';
import Footer from './Footer';

export interface IAboutUsProps { }

const AboutUs: React.FunctionComponent<IAboutUsProps> = (props) => {
    // const AboutUs = () => {
    // const AboutSlides = () => (imageList.map((image, index) => (
    // <div key={index}>
    //     <div style={{ ...divStyle, 'backgroundImage': `url(${image.default})` }}>
    //     </div>
    // </div>
    //    <img key={index} src={image.default} alt={`image-${index}`} />
    // )));
    const fadeAbout = [
        { url: about1 }, { url: about2 }, { url: about3 }, { url: about4 }, { url: about5 }, { url: about6 }, { url: about7 }, { url: about8 },
        { url: about9 }, { url: about10 }, { url: about11 }, { url: about12 }, { url: about13 }, { url: about14 }, { url: about15 }, { url: about16 },
        { url: about17 }, { url: about18 }, { url: about19 }, { url: about20 }
    ];
    const fadeImages = [
        {
            // url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
            url: banner1,
            caption: 'First Slide'
        },
        {
            // url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
            url: banner2,
            caption: 'Second Slide'
        },
        {
            // url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
            url: banner3,
            caption: 'Third Slide'
        },
        {
            // url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
            url: banner4,
            caption: 'Fourth Slide'
        },
        {
            // url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
            url: banner5,
            caption: 'Fifth Slide'
        },
    ];

    const fadeTeam = [
        {
            url: team1,
            name: 'Kamalakar Sutar',
            details: <div><p>&emsp;&emsp;With over 22 years of extensive work experience, predominantly in the Oil & Gas sector, I bring a wealth of expertise to the table. As the Director of TechTurn Engg Services LLP based in Navi Mumbai, I lead a team dedicated to delivering excellence in engineering solutions.</p>
                <p>&emsp;&emsp;My professional journey includes pivotal roles with renowned organizations such as Global Process Systems in Singapore, Wood Group CCC Ltd. in Oman, Petrofac Engineering (I) Pvt. Ltd. in Mumbai, and Jacobs Engineering (I) Pvt. Ltd. in Navi Mumbai. These roles have honed my skills as a Senior Piping Engineer, PDMS-PDS Administrator, and Senior Design Engineer, among others.</p></div>
        },
        {
            url: team2,
            name: "Ganesh Palav",
            details: <div><p>&emsp;&emsp;With more two decayed of extensive experience in EPC projects, specializing in Layout design, I have had the privilege of serving esteemed clients across various industries including Refineries, Oil & Gas Plants, Mining Plants, PVC Plants, Steel Plants, and Soda-ash Plants as a proficient Piping Expert.</p>
                <p>&emsp;&emsp;Currently, I hold the position of Director at TECHTURN ENGINEERING SERVICES LLP in Navi Mumbai, a role I have successfully undertaken since April 2017. In this capacity, I have been instrumental in driving the company\'s growth and success through strategic leadership and innovative solutions. </p>
                <p>&emsp;&emsp;Prior to my tenure at TECHTURN, I contributed my expertise to the renowned team at DODSAL ENGG AND CONST PTE. LTD. in Dubai, where I dedicated almost four years to delivering exceptional results in the field of piping engineering. My journey towards excellence commenced at JACOBS ENGINEERING INDIA PVT. LTD. in Navi Mumbai, where I spent over nine years refining my skills and knowledge as a Piping Engineer, laying a solid foundation for my career. Before joining Jacobs Engineering, I accumulated invaluable experience by working with four different consultancy firms for over seven years, further enriching my understanding of the industry and its intricacies. Throughout my professional trajectory, I have remained committed to delivering superior quality and exceeding client expectations, earning a reputation for reliability, expertise, and dedication to excellence.</p></div>
        },
        {
            url: team3,
            name: 'Prashant Goregaonkar',
            details: <div><p>I currently serve as the Technical Associate Director at TechTurn Engineering Services LLP in Mumbai, where I play a pivotal role in driving technical excellence and contributing to the organization's success.</p>
                <p>Bringing nearly 26 years of extensive experience in Piping Engineering Operations with a specialization in stress analysis, I have successfully executed projects across various sectors including offshore oil & gas, refineries, chemical/petrochemical plants, and polyester plants. My career journey has been defined by a commitment to delivering excellence and driving technical innovation in every endeavours.</p>
            </div>
        },
        {
            url: team4,
            name: 'Goutam Nath',
            details: <div><p>With over 37 years of professional experience, I, Gautam Nath, am currently serving as the Head of Piping & Stress at TechTurn Engineering Services LLP since October 2017. My academic journey includes a bachelor’s degree in mechanical engineering from Jadavpur University, India. Throughout my career, I have amassed extensive experience as an engineer and leader, contributing to major projects in the realm of Piping Design & Engineering for both offshore and onshore applications across various esteemed organizations.</p>
                <p>Throughout my tenure, I have consistently demonstrated a strong commitment to delivering excellence and contributing effectively to the success of each project I have been a part of.</p>
            </div>
        },
    ];
    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '600px'
    }

    return (
        <div>
            <Header />
            <div className="slide-container">
                <div className="inner-banner">
                </div>
                <section className="w3l-breadcrumb">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><a href="index.html">Home</a></li>
                            <li className="active"><span className="fa fa-arrow-right mx-2" aria-hidden="true"></span> About Us</li>
                        </ul>
                    </div>
                </section>

                <section className="w3l-feature-with-photo-1 ab-pagew3-in">
                    <div className="feature-with-photo-hny py-5">
                        <div className="container py-lg-5">
                            <div className="feature-with-photo-content">
                                <div className="row mb-lg-5 mb-3">
                                    <div className="col-lg-6 ab-left">
                                        <Zoom arrows={false} duration={500} scale={0}>
                                            {fadeAbout.map((fadeImage, index) => (
                                                <div key={index}>
                                                    <div style={{ ...divStyle, 'backgroundImage': `url(${fadeImage.url})` }}>
                                                    </div>
                                                </div>))}
                                        </Zoom>
                                    </div>
                                    <div className="col-lg-6 ab-right pl-lg-5 mt-lg-0 mt-5">
                                        <h6 className="title-subhny mb-2">Vision</h6>
                                        <h3 className="title-w3l mb-4"><span>Turning Ideas into Technical Triumphs</span></h3>
                                        <p className="my-4">At TECHTURN ENGINEERING, our vision is to lead the way in engineering design excellence, setting new standards for innovation, sustainability, and client satisfaction. We strive to be pioneers in turning visionary concepts into tangible realities, leveraging our technical expertise, creativity, and commitment to quality.
                                            We envision our company as a beacon of technical excellence, continually pushing the boundaries of what is possible in engineering design.
                                        </p>
                                        <a href="services.html" className="btn btn-style btn-primary mt-2">Read More </a>
                                    </div>
                                </div>
                                <div className="row w3l-features-1-content align-self mt-5 pt-lg-5">
                                    <div className="col-lg-4 features-1-right pr-lg-5 p-lg-0 mb-lg-0 mb-5">
                                        <div className="features-1-right-content text-left">
                                            <h3 className="title-subhny mb-2">What We Offer</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 features-1-left pl-lg-5 p-lg-0">
                                        <div className="w3-features-1-grids">
                                            <div className="w3-features-1-grid">
                                                <div className="features-1-icon">
                                                    <span className="fas fa-drafting-compass"></span>
                                                </div>
                                                <h5><a href="#">Quality Work</a></h5>
                                            </div>
                                            <div className="w3-features-1-grid">
                                                <div className="features-1-icon">
                                                    <span className="fas fa-users"></span>
                                                </div>
                                                <h5><a href="#">Expert Team</a></h5>
                                            </div>
                                            <div className="w3-features-1-grid">
                                                <div className="features-1-icon">
                                                    <span className="fas fa-headset"></span>
                                                </div>
                                                <h5><a href="#">24/7 Suport</a></h5>
                                            </div>
                                            <div className="w3-features-1-grid">
                                                <div className="features-1-icon">
                                                    <span className="fas fa-layer-group"></span>
                                                </div>
                                                <h5><a href="#">Advices</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section className="w3l-featureshny py-5" id="features" >
                    <div className="container py-md-5">
                        <div className="row">
                            <div className="col-lg-6 statsw3-left pl-lg-5">
                                <h5>Mission</h5>
                                <p className="">At TECHTURN ENGINEERING, our mission is to pioneer innovative solutions, consistently delivering engineering excellence while making a sustainable impact. We are driven by a commitment to surpass client expectations, leveraging cutting-edge technologies and creative problem-solving. With a focus on integrity and collaboration, we strive to shape a future where our designs positively influence industries and communities worldwide.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w3l-clients py-5" id="clients">
                    <div className="cusrtomer-layout py-5">
                        <div className="container">
                            <div className="heading text-center mx-auto">
                                <h3 className="title-w3l mb-5">Our Team</h3>
                            </div>
                            <div>
                                <Fade autoplay={false}>
                                    {fadeTeam.map((fade, index) => (
                                        <div className="testimonial">
                                            <i className="fas fa-quote-left"></i>
                                            <p>{fade.details}</p>
                                            <div className="testi-des">
                                                <div className="test-img"><img src={fade.url} className="img-fluid" alt="client-img" />
                                                </div>
                                                <div className="peopl align-self">
                                                    <h3>{fade.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>

            </div >
            <Footer />
        </div>
    );
}

export default AboutUs;