import React, { ReactNode, useEffect, useState } from 'react';
import Carousel, { CarouselItem } from "./Carousel";
import { Fade, Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

export interface IServiceProps {
}

const Contact: React.FunctionComponent<IServiceProps> = (props) => {
    return (
        <div>
            <Header />
            <div className="inner-banner">
            </div>
            <section className="w3l-breadcrumb">
                <div className="container">
                    <ul className="breadcrumbs-custom-path">
                        <li><Link className="nav-link" to={"/"}>Home</Link></li>
                        <li className="active"><span className="fa fa-arrow-right mx-2" aria-hidden="true"></span> Contact Us</li>
                    </ul>
                </div>
            </section>
            <section className="w3l-contact-1 py-5" id="contact">
                <div className="contacts-9 py-lg-5 py-md-4">
                    <div className="container">
                        <div className="contactct-fm map-content-9">
                            <div className="header-title text-center">
                                <h6 className="title-subhny"><span>contact Us</span></h6>
                                <h3 className="title-w3l mb-2">Let’s Talk About
                                    <span className="span-bold"> Your Project</span></h3>
                                <p className="mb-sm-5 mb-4">Start working with Us that can provide everything you need to generate awareness,
                                    drive traffic,
                                    connect. <br /> We guarantee that you’ll be able to have any issue resolved within 24 hours.</p>
                            </div>
                            <form action="#" className="pt-lg-4" method="post">
                                <div className="twice-two">
                                    <input type="text" className="form-control" name="Name" placeholder="Name" required />
                                    <input type="email" className="form-control" name="Email" placeholder="Email" required />
                                    <input type="text" className="form-control" name="Subject" placeholder="Subject" required />
                                </div>
                                <textarea name="Message" className="form-control" placeholder="Message" required></textarea>
                                <div className="text-lg-center">
                                    <button type="submit" className="btn btn-primary btn-style mt-lg-5 mt-4">Send Message</button>
                                </div>
                            </form>
                        </div>
                        <div className="row contact-view mt-5 pt-lg-5">
                            <div className="col-lg-4 col-md-6 cont-details">
                                <div className="contactct-fm-text text-left">
                                    <h6 className="title-subhny"><span>Reach Us</span></h6>
                                    <h3 className="title-w3l mb-5">Mr. Ganesh Palav</h3>
                                    <div className="cont-top">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-phone-alt"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h5>Phone number</h5>
                                            <p><a href="tel:+(91) 9930419521">+(91) 99304 19521</a></p>
                                        </div>
                                    </div>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-envelope-open-text"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h5>Send Email</h5>
                                            <p><a href="mailto:ganesh.palav@techturn.co.in" className="mail">ganesh.palav@techturn.co.in</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 cont-details">
                                <div className="contactct-fm-text text-left">
                                    <h6 className="title-subhny"><span></span></h6>
                                    <h3 className="title-w3l mb-5">Mr. Kamalakar Sutar</h3>
                                    <div className="cont-top">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-phone-alt"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h5>Phone number</h5>
                                            <p><a href="tel:+(91) 9930418521">+(91) 99304 18521</a></p>
                                        </div>
                                    </div>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-envelope-open-text"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h5>Send Email</h5>
                                            <p><a href="mailto:kamalakar.sutar@techturn.co.in" className="mail">kamalakar.sutar@techturn.co.in</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 cont-details">
                                <div className="contactct-fm-text text-left">
                                    <h6 className="title-subhny"><span></span></h6>
                                    <h3 className="title-w3l mb-5">Mr. Prashant Goregaonkar</h3>
                                    <div className="cont-top">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-phone-alt"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h5>Phone number</h5>
                                            <p><a href="tel:+(91) 7506432386">+(91) 75064 32386</a>       <a href="tel:+(65) 7506432386">+(65) 90042850</a></p>
                                        </div>
                                    </div>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-envelope-open-text"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h5>Send Email</h5>
                                            <p><a href="mailto:kamalakar.sutar@techturn.co.in" className="mail">kamalakar.sutar@techturn.co.in</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row contact-view mt-5 pt-lg-5">
                            <div className="col-lg-4 col-md-6 cont-details mt-lg-0 mt-6">
                                <div className="contactct-fm-text text-left">
                                    <h6 className="title-subhny"><span>Find Us</span></h6>
                                    <h3 className="title-w3l">Registered Office</h3>
                                    <h6 className="title-subhny">Mumbai</h6>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-map-marker-alt"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h6>TechTurn Engineering Services LLP</h6>
                                            <p className="pr-lg-5">B-302, Tulip CHS, Plot 7, Sector 6, Ghansoli,  Navi Mumbai 400701</p>
                                            <a href="mailto:enquiry@techturn.co.in" className="mail">enquiry@techturn.co.in</a><br />
                                            <a href="mailto:techturnenginering@gmail.com" className="mail">techturnenginering@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 cont-details mt-lg-0 mt-6">
                                <div className="contactct-fm-text text-left">
                                <h6 className="title-subhny"><span></span></h6>
                                    <h3 className="title-w3l">Branch Office</h3>
                                    <h6 className="title-subhny">Singapore</h6>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fas fa-map-marker-alt"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h6>TechTurn Engineering Services PTE. LTD.</h6>
                                            <p className="pr-lg-5">89, Short Street, #08-06,  Golden Wall Centre Singapore (188216)</p>
                                            <a href="mailto:prashant.goregaonkar@techturn.co.in" className="mail">prashant.goregaonkar@techturn.co.in</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="map-content-9">
                <div className="map-iframe">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1UdJD7OE8e5ztEgM64Np9M94odUExdVc&ehbc=2E312F&noprof=1" 
                    width="100%" height="400" style={{ border: "0px" }} allowFullScreen></iframe>
                    {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317718.69319292053!2d-0.3817765050863085!3d51.528307984912544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C+UK!5e0!3m2!1sen!2spl!4v1562654563739!5m2!1sen!2spl"
                        width="100%" height="400" frameBorder={"0"} style={{ border: "0px" }} allowFullScreen></iframe> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;