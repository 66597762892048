import React, { ReactNode, useEffect, useState } from 'react';
import Carousel, { CarouselItem } from "./Carousel";
import { Fade, Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import project1 from './images/projects/biocon_api_plant.png';
import project2 from './images/projects/dnl_benzo_tri_fluoride_phenyl_hydrazine.png';
import project3 from './images/projects/dnl_benzo_tri_fluoride_phenyl_hydrazine_overall_plot_plan.png';
import project4 from './images/projects/granules_multi_purpose_plant.png';
import project5 from './images/projects/gujrat_refineries_reformer_unit.png';
import project6 from './images/projects/gujrat_refineries_reformer_unit_02.png';
import project7 from './images/projects/ips_benzochem_project.png';
import project8 from './images/projects/ips_benzochem_project_1.png';
import project9 from './images/projects/psv_manifold.png';

export interface IServiceProps {
}

const Service: React.FunctionComponent<IServiceProps> = (props) => {
    const fadeProject = [
        { url: project1, caption: "Biocon API Plant" }, { url: project2, caption: "DNL BENZO Tri fluoride & Phenyl Hydrazine" },
        { url: project3, caption: "DNL BENZO Tri fluoride & Phenyl Hydrazine Overall Plot Plan" },
        { url: project4, caption: "Granules Multi Purpose Plant" }, { url: project5, caption: "Gujrat Refineries Reformer Unit" },
        { url: project6, caption: "Gujrat Refineries Reformer Unit 2" }, { url: project7, caption: "IPS Benzochem Project" },
        { url: project8, caption: "Biocon API Plant 1" }, { url: project9, caption: "PSV Manifold" },
    ];

    const fadeCaseStudies = [
        {
            background: "TechTurn Engineering was honoured to receive an invitation from Maire Tecnimont, formerly known as Tecnimont ICB Private Limited, to assist in the development of 200 pipe supports for their standardization initiative. This presented a formidable challenge, as the tight timeframe of just 2 months demanded unparalleled efficiency and innovation from our team.",
            requirement: "Undeterred by the complexity of the task, our dedicated engineers embraced the challenge with unwavering determination and ingenuity. Leveraging our deep expertise in PDMS software and engineering design, we embarked on the development of a bespoke Pipe Support Add-On Tool tailored specifically to meet Maire Tecnimont's unique requirements.",
            solution: "Through tireless collaboration and meticulous attention to detail, our team worked tirelessly to conceptualize, design, and implement a robust solution within the stipulated timeframe. The result was a cutting-edge tool that streamlined the process of support standardization, significantly enhancing efficiency and accuracy in piping design.",
            achievements: "Upon delivery, Maire Tecnimont expressed their utmost appreciation for the exceptional quality and timeliness of our work. Our ability to meet and exceed their expectations underscored our commitment to excellence and cemented our reputation as a trusted partner in engineering design. This successful collaboration stands as a testament to TechTurn Engineering's unwavering dedication to delivering innovative solutions that empower our clients to achieve their goals efficiently and effectively. We are immensely proud of our team's accomplishments and look forward to continuing to exceed expectations in future endeavours."
        },
        {
            background: "TechTurn Engineering was privileged to be approached by Tecnoquest Consultancy for their flagship in-house project, the Continuous Polymer Project (CP). This ambitious endeavour encompassed the meticulous 3D modelling of 1500 lines across 43 PID diagrams, as well as the detailed modelling of 280 equipment units, including the critical task of stress analysis for key lines.",
            requirement: "Undertaking such a substantial workload within a stringent timeframe of 11months presented a formidable challenge, but one that our team embraced with unwavering determination and expertise.",
            solution: "Through relentless dedication and collaborative effort, we successfully delivered all engineering aspects of the CP project within the specified timeframe, meeting every milestone with precision and excellence. Our commitment to quality and efficiency ensured not only the completion of the project but also the satisfaction of our client.",
            achievements: "The successful completion of the CP project not only attests to TechTurn Engineering's capability to handle complex engineering tasks efficiently but also underscores our dedication to exceeding client expectations. We are delighted that our performance has garnered the appreciation of Tecnoquest Consultancy, leading to the awarding of another two project, a testament to our reputation for excellence and reliability in the field of engineering design."
        },
        {
            background: "TechTurn Engineering was entrusted by GEA Process Engineering India Pvt Ltd. with the crucial task of Piping Engineering design and 3D modelling for the Evaporation Unit project at UTKAL Refineries. With 350 lines across 18 PID diagrams and 80 equipment units, 110 Nos Lines Stress Analysis Including 72” Duct lines, the project presented significant challenges.",
            requirement: "Among these challenges, the stress analysis of Pump Suction and discharge lines, as well as the support design for 72” Duct lines, required meticulous attention and expertise.",
            solution: "Our dedicated team of engineers rose to the occasion, accepting these challenges head-on. Through innovation, collaboration, and unwavering determination, we successfully delivered solutions that met and exceeded expectations.",
            achievements: "Today, the Evaporation unit stands as a testament to our commitment to excellence, with smooth operations at UTKAL Refineries. This showcases TechTurn Engineering's ability to tackle complex projects and deliver results that drive success. Additionally, GEA Process Engineering India Pvt Ltd. expressed their appreciation for the exceptional piping engineering support and stress analysis provided by TechTurn Engineering Services LLP, further highlighting our dedication to exceeding client expectations."
        }
    ];

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '300px'
    }

    return (
        <div>
            <Header />
            <div className="inner-banner">
            </div>

            <section className="w3l-breadcrumb">
                <div className="container">
                    <ul className="breadcrumbs-custom-path">
                        <li><Link className="nav-link" to={"/"}>Home</Link></li>
                        <li className="active"><span className="fa fa-arrow-right mx-2" aria-hidden="true"></span> Services</li>
                    </ul>
                </div>
            </section>
            <section className="w3l-services2" id="services">
                <div id="cwp23-block" className="py-5">
                    <div className="container py-lg-5">
                        <div className="row cwp23-content mt-lg-5 mt-4">
                            <div className="col-lg-6 cwp23-img">
                                <h6 className="title-subhny mb-2"><span>Our Specialization</span></h6>
                                <div style={{ fontSize: '12px' }}>
                                    <div className='row'><i className="fa fa-angle-double-right"></i><h6>Complete 3D Modelling Support in Various 3d Software Like…</h6></div>
                                    <br />
                                    <div className='row'>
                                        <i className='col-md-3 fas fa-bullseye'>  E3D</i><i className='col-md-3 fas fa-bullseye'>  PDMS</i><i className='col-md-3 fas fa-bullseye'>  AVEVA PID</i><i className='col-md-3 fas fa-bullseye'>  PLANT 3D</i>
                                    </div>
                                    <div className='row'>
                                        <i className='col-md-3 fas fa-bullseye'>  AUTOCAD</i><i className='col-md-3 fas fa-bullseye'>  SP3D</i><i className='col-md-3 fas fa-bullseye'>  PDS</i><i className='col-md-3 fas fa-bullseye'>  CEASER</i>
                                    </div>
                                    <div className='row'>
                                        <i className='col-md-3 fas fa-bullseye'>  STAAD</i><i className='col-md-3 fas fa-bullseye'>  TEKLA</i><i className='col-md-3 fas fa-bullseye'>  CADWORX</i>
                                    </div>
                                    <br />
                                    <div className='row'><i className="fa fa-angle-double-right"></i><h6>Detail Engineering Services in Multi-Discipline like…</h6></div>
                                    <br />
                                    <div className='row'>
                                        <i className='col-md-3 fas fa-bullseye'>  PIPING</i><i className='col-md-3 fas fa-bullseye'>  PROCESS</i><i className='col-md-3 fas fa-bullseye'>  STRUCTURAL</i><i className='col-md-3 fas fa-bullseye'>  ELECTRICAL</i>
                                    </div>
                                    <div className='row'>
                                        <i className='col-md-3 fas fa-bullseye'>  MECHANICAL</i><i className='col-md-3 fas fa-bullseye'>  INSTRUMENTATION</i><i className='col-md-3 fas fa-bullseye'>  CIVIL</i><i className='col-md-3 fas fa-bullseye'>  HVAC</i>
                                    </div>
                                    <br />
                                    <div className='row'><i className="fa fa-angle-double-right"></i><h6>Piping Stress Analysis</h6></div>
                                    <br />
                                    <div className='row'><i className="fa fa-angle-double-right"></i><h6>Man Power Services</h6></div>
                                    <br />
                                    <div className='row'><i className="fa fa-angle-double-right"></i><h6>Software Customization</h6></div>
                                    <br />
                                    <div className='row'><i className="fa fa-angle-double-right"></i><h6>Software Training</h6></div>
                                </div>
                            </div>
                            <div className="col-lg-6 cwp23-text mt-lg-0 mt-5 pl-lg-5">
                                <div className="imgw3l-ab w3l-news">
                                    <div className="grids5-info">
                                        <Zoom arrows={false} duration={500} scale={0}>
                                            {fadeProject.map((fadeP, index) => (
                                                <div key={index}>
                                                    <div style={{ ...divStyle, 'backgroundImage': `url(${fadeP.url})` }}>
                                                    </div>
                                                    <h4>{fadeP.caption}</h4>
                                                </div>))}
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w3l-services3" id="services">
                <div className="new-block">
                    <div className="container py-5">
                        <div className="middle-section py-lg-5 py-4">
                            <div className="section-width">
                                <h3 className="title-w3l two mb-4"> <i className="fas fa-quote-left"></i><i> Turning Ideas into
                                    <br /> &emsp;&emsp;Technical Triumphs</i></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w3l-grids-3 py-5">
                <div className="container py-md-5 py-3">
                    <div className="row bottom-ab-grids align-items-center">
                        <div className="col-lg-6 bottom-ab-left">
                            <h6 className="title-subhny mb-2"><span>Case Studies</span></h6>
                            <h3 className="title-w3l mb-4">Start experiencing amazing industrial business</h3>
                        </div>
                    </div>
                    <div className="col-lg-12 bottom-ab-right mt-lg-0 mt-3 pl-lg-4">
                        <Slide duration={500} autoplay={false}>
                            {fadeCaseStudies.map((fadeP, index) => (
                                <div key={index}>
                                    <h6>Background:</h6> <p>{fadeP.background}</p>
                                    <h6>Requirements:</h6><p>{fadeP.requirement}</p>
                                    <h6>Our Solution:</h6><p>{fadeP.solution}</p>
                                    <h6>The Achievements:</h6> <p>{fadeP.achievements}</p>
                                </div>))}
                        </Slide>
                    </div>
                    <section>
                        <div className="new-block">
                            <div className="row bottom_grids pt-md-3 text-left">
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-check-circle" aria-hidden="true"></span>
                                            <h6 className="">Complete 3d Administration Support for Various 3d Software.</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-american-sign-language-interpreting" aria-hidden="true"></span>
                                            <h6 className="">Complete Stress Analysis Solution for Critical Pipes.</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-drafting-compass"></span>
                                            <h6 className="">Plot Plan Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-map"></span>
                                            <h6 className="">Unit Area Development</h6>
                                        </a>
                                    </div>  
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-broadcast-tower" aria-hidden="true"></span>
                                            <h6 className="">Equipment Layout Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-industry" aria-hidden="true"></span>
                                            <h6 className="">Piping Study Layout</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-pencil-ruler" aria-hidden="true"></span>
                                            <h6 className="">Piping Layout Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-sync" aria-hidden="true"></span>
                                            <h6 className="">Nozzle Orientation</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-crop" aria-hidden="true"></span>
                                            <h6 className="">Piping Design Basis Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-crosshairs" aria-hidden="true"></span>
                                            <h6 className="">Stress Design Basis Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-life-ring" aria-hidden="true"></span>
                                            <h6 className="">Pipe Support Standard Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-industry" aria-hidden="true"></span>
                                            <h6 className="">3D Modelling of Various Discipline in Various Software</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-snowflake" aria-hidden="true"></span>
                                            <h6 className="">3 Stage MTO Preparation</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-search" aria-hidden="true"></span>
                                            <h6 className="">30/60/90 Model Review Preparation</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-grip-vertical" aria-hidden="true"></span>
                                            <h6 className="">Pipe Stress Analysis of Critical Lines</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-external-link-alt" aria-hidden="true"></span>
                                            <h6 className="">Isometric Extraction</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-draw-polygon" aria-hidden="true"></span>
                                            <h6 className="">Pipe Support Drawing Development</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mt-5">
                                    <div className="grid-block" style={{width: '250px', height: '250px'}}>
                                        <a className="d-block p-lg-4 p-3">
                                            <span className="fas fa-expand-alt" aria-hidden="true"></span>
                                            <h6 className="">Final 3d Model Submission as per Client Requirements.</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Service;