import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import Slideshow from './slideshow';
import AboutUs from './AboutUs';

const Header = () => {
    const [scrolled, setScrolled] = useState("fixed-top");


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        // $(window).scrollTop() == 80 ? $("#site-header").addClass("nav-fixed") : $("#site-header").removeClass("nav-fixed");
        var d = document.documentElement;
        var offset = d.scrollTop + window.innerHeight;
        var height = d.offsetHeight;
        d.scrollTop > 80 ? setScrolled("fixed-top nav-fixed") : setScrolled("fixed-top");
    }

    return (
        <div>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
                <title>Secttore Industrial Category Bootstrap Responsive Web Template | Home :: W3layouts</title>
                <link href="//fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap"
                    rel="stylesheet"></link>
                <link rel="stylesheet" href="assets/css/style-starter.css"></link>
            </head>

            <header id="site-header" className={scrolled}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg stroke px-0 pt-lg-0">
                        <h1><Link className="navbar-brand" to={'/'}>Tech<i className="fas fa-text-width"></i>urn</Link>
                        </h1>
                        {/* <a className="navbar-brand" href="#index.html">
                            <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
                        </a> */}
                        <button className="navbar-toggler collapsed bg-gradient" type="button" data-toggle="collapse"
                            data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
                            <span className="navbar-toggler-icon fa icon-close fa-times"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav mr-lg-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to={"/"}></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/about"}>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/services"}>Services</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to={"/contact"}>Contact</Link>
                                </li>
                            </ul>
                            <div className="top-quote mr-lg-3 mt-lg-0 mr-lg-4">
                                <Link className="btn btn-style btn-white btn-primary" to={"/contact"}>Request a Quote</Link>
                            </div>
                        </div>
                        <div className="mobile-position">
                            <nav className="navigation">
                                <div className="theme-switch-wrapper">
                                    <label className="theme-switch">
                                        <input type="checkbox" id="checkbox"></input>
                                        <div className="mode-container">
                                            <i className="gg-sun"></i>
                                            <i className="gg-moon"></i>
                                        </div>
                                    </label>
                                </div>
                            </nav>
                        </div>
                    </nav>
                </div>
            </header>

        </div>
    )
}

export default Header;